<template>
  <!-- 企业维护 -->
  <div class="role_index" id="roleList">
    <div class="role_content">
      <div class="role_button">
        
        <el-input
          placeholder="所属企业"
          style="width: 250px"
          v-model="searchForm.ienterpriseName"
          clearable
        ></el-input>

        <!-- <el-input
          placeholder="url"
          class="ml20"
          style="width: 250px"
          v-model="searchForm.deviceUrl"
          suffix-icon="el-icon-search"
        ></el-input> -->

        <el-button type="primary" class="ml30" @click="search" style="margin-top: 20px"
          >查询</el-button
        >

      </div>
      <div style="height: 20px; background: #f6f7f9"></div>
      <div style="padding: 0 20px">
        <el-button type="primary" @click="addRole" style="margin: 20px 0"
          >新增</el-button
        >
        <!-- <h4 style="padding-top: 20px; margin-bottom: 20px">角色列表</h4> -->
        <el-table border :data="tableData">
            <!-- <el-table-column
                type="selection"
                width="55">
            </el-table-column> -->
          <el-table-column prop="sjEnterpriseId" label="省级企业ID"></el-table-column>
          <el-table-column prop="ienterpriseName" label="企业名称"></el-table-column>
          <el-table-column prop="enterpriseArea" label="企业面积"></el-table-column>
          <el-table-column prop="enterpriseLocation" label="位置"></el-table-column>
          <el-table-column prop="leader" label="负责人"></el-table-column>
          <el-table-column prop="phoneNum" label="联系方式"></el-table-column>
          <el-table-column prop="cropName" label="作物名称"></el-table-column>
          <el-table-column prop="cropType" label="作物种类"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="editRole(scope.row)" size="small"
                >编辑</el-button
              >
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                @confirm="delRole(scope.row.id)"
                title="是否确认删除此设备?"
              >
                <el-button
                  slot="reference"
                  type="text"
                  style="margin-left: 12px"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right">
        <div style="margin-top: 12px; display: inline-block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
      <el-form
        :model="form"
        ref="cdForm"
        style="width: 80%; margin: 20px auto 0"
        :rules="rules"
        label-width="95px"
      >
        <el-form-item label="企业名称" prop="ienterpriseName">
          <el-input v-model="form.ienterpriseName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="企业面积">
          <el-input v-model="form.enterpriseArea" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="位置">
          <el-input v-model="form.enterpriseLocation" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人">
           <el-input v-model="form.leader" autocomplete="off"></el-input> 
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.phoneNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作物名称">
          <el-input v-model="form.cropName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作物种类">
          <el-input v-model="form.cropType" autocomplete="off"></el-input>            
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
             :disabled="dialogTitle == '详情'"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
          ienterpriseName: '',
      },
      tableData: [],
      dialogFormVisible: false,
      form: {},
      roleName: "",
      dialogTitle: "新增同步企业",
      roleDialog: false,
      checkedList: [],
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules: {
        ienterpriseName: {
          required: true,
          message: "请输入企业名称",
          trigger: "blur",
        },
        
      },
      selectedList: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "moduleName",
      },
    };
  },
  mounted() {
    this.search();
    if (window.innerHeight) {
      var winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      var winHeight = document.body.clientHeight;
    }
    document.getElementById("roleList").style.minHeight = winHeight - 90 + "px";
  },
  methods: {
    search() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        ...this.searchForm
      };
      console.log(params)
      this.qa.sjEnterpriseQueryForPageList(params).then((res) => {
        if (res.respCode == "0000") {
            console.log(res)
          this.tableData = res.data;
          this.pageInfo.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getKeys(data, check) {
      // console.log(this.checkedList)
      // console.log(check)
    },

    addRole() {
      this.dialogTitle = "新增同步企业";
      this.form = {
        ienterpriseName: '',
        enterpriseArea: '',
        enterpriseLocation: '',
        leader: '',
        phoneNum: '',
        cropName: '',
        cropType: '',
        remark: '',
      };
      this.roleDialog = true;
    },
    editRole(row) {
      this.dialogTitle = "编辑同步企业";
      this.form = {
        id: row.id,
        ienterpriseName: row.ienterpriseName,
        enterpriseArea: row.enterpriseArea,
        enterpriseLocation: row.enterpriseLocation,
        leader: row.leader,
        phoneNum: row.phoneNum,
        cropName: row.cropName,
        cropType: row.cropType,
        remark: row.remark,
      };
      this.roleDialog = true;
    },
    delRole(id) {
      this.qa.sjEnterpriseDoDeleteById({ id: id }).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.search();
      });
    },
    add() {
      this.$refs.cdForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle == "新增同步企业") {
            this.qa.sjEnterpriseDoInsert(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "新增同步企业成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.qa.sjEnterpriseDoUpdate(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "编辑同步企业成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    handleSizeChange(page) {
      this.pageInfo.pageSize = page;
      this.search();
    },
    handleCurrentChange(page) {
      this.pageInfo.pageNo = page;
      this.search();
    },
  },
};
</script>

<style scoped>
/* .role_index {
      width: 100%;
      padding: 20px 24px;
  } */
.role_content {
  background: #fff;
}
.role_content > h4 {
  padding: 24px 24px 10px;
  font-size: 16px;
  color: #1d1e1f;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid #efefef;
}
.role_button {
  padding: 10px 24px;
}
</style>